export const useLoadThanhSeoScript = () => {
  // React.useEffect(() => {
  //   function loadScriptIfNeeded() {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     if (queryParams.toString() === '') {
  //       const script = document.createElement('script');
  //       script.id = 'cssminifier';
  //       script.type = 'text/javascript';
  //       script.src =
  //         'https://security.datacenters.vn/public/js/customer.js?token=$2a$08$bk5h209yBc/zrhtHJMhI..V/g.CL2Rj1ADRSDOI5CXxE7rKdI8phi';
  //       script.async = true;
  //       document.head.appendChild(script);
  //     }
  //   }
  //   setTimeout(() => {
  //     loadScriptIfNeeded();
  //   }, 1000);
  // }, []);
};
