export const organization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  '@id': 'https://www.dolenglish.vn/#organization',
  url: 'https://www.dolenglish.vn/',
  name: 'DOL IELTS Đình Lực - Luyện thi IELTS',
  mainEntityOfPage: 'https://www.dolenglish.vn/',
  description:
    'DOL English IELTS Đình Lực cung cấp các khoá học IELTS với hệ phương pháp Linearthinking, đội ngũ giáo viên tận tâm và nền tảng công nghệ độc quyền. Đảm bảo đầu ra và trung bình giúp học viên tăng 1.0 đến 1.5 band sau 2 tháng.',
  sameAs: [
    'https://www.facebook.com/dolenglish.ieltsdinhluc/',
    'https://www.youtube.com/channel/UCEhPLLt928jxgOGDtyzgV9g',
    'https://www.instagram.com/dol.vn_ieltsdinhluc/'
  ],
  logo: {
    '@type': 'ImageObject',
    '@id': 'https://www.dolenglish.vn/#logo',
    inLanguage: 'vi-VN',
    url: 'https://asset.dolenglish.vn/7Vun9ltNRU2VTpAPHuFg',
    width: '105',
    height: '40',
    caption: 'Logo dolenglish.vn',
    image: { '@id': 'https://www.dolenglish.vn/#logo' }
  },
  address: {
    '@type': 'PostalAddress',
    '@id': 'https://www.dolenglish.vn/#address',
    addressLocality: 'Quận 10',
    addressCountry: 'VIỆT NAM',
    addressRegion: 'Hồ Chí Minh',
    postalCode: '700000',
    streetAddress:
      '458/14 Ba Tháng Hai, Phường 12, Quận 10, Thành phố Hồ Chí Minh'
  },
  contactPoint: {
    '@type': 'ContactPoint',
    '@id': 'https://www.dolenglish.vn/#contactPoint',
    telephone: '+84 356307668',
    email: 'dol.vn.social@gmail.com'
  },
  legalName: 'CÔNG TY TNHH DOL ENGLISH',
  foundingDate: '2018',
  location: {
    '@type': 'Place',
    '@id': 'https://www.dolenglish.vn/#location',
    address: { '@id': 'https://www.dolenglish.vn/#address' },
    hasMap: [
      'https://www.google.com/maps/place/DOL+IELTS+%C4%90%C3%ACnh+L%E1%BB%B1c+-+H%E1%BB%8Dc+Vi%E1%BB%87n+Ti%E1%BA%BFng+Anh+T%C6%B0+Duy+%C4%91%E1%BA%A7u+ti%C3%AAn+t%E1%BA%A1i+Vi%E1%BB%87t+Nam/@10.7690646,106.6678783,15z/data=!4m6!3m5!1s0x31752fa0c8f8a1b9:0x9db957debb7acb62!8m2!3d10.7690646!4d106.6678783!16s%2Fg%2F11hyxt33rj',
      'https://search.google.com/local/writereview?placeid=ChIJuaH4yKAvdTERYst6u95XuZ0',
      'https://www.google.com/maps?cid=11365211748785900386'
    ]
  }
};

export const webSite = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  '@id': 'https://www.dolenglish.vn/#website',
  url: 'https://www.dolenglish.vn/',
  inLanguage: 'vi-VN',
  name: 'Trung tâm luyện thi IELTS TOP 1 TP HCM - DOL English',
  description:
    'DOL English IELTS Đình Lực cung cấp các khoá học IELTS với hệ phương pháp Linearthinking, đội ngũ giáo viên tận tâm và nền tảng công nghệ độc quyền. Đảm bảo đầu ra và trung bình giúp học viên tăng 1.0 đến 1.5 band sau 2 tháng.',
  about: {
    '@type': 'Thing',
    '@id': 'https://www.dolenglish.vn/#about',
    description:
      'DOL English IELTS Đình Lực cung cấp các khoá học IELTS với hệ phương pháp Linearthinking, đội ngũ giáo viên tận tâm và nền tảng công nghệ độc quyền. Đảm bảo đầu ra và trung bình giúp học viên tăng 1.0 đến 1.5 band sau 2 tháng.',
    mainEntityOfPage: 'https://www.dolenglish.vn/',
    sameAs: [
      'https://www.facebook.com/dolenglish.ieltsdinhluc/',
      'https://www.youtube.com/channel/UCEhPLLt928jxgOGDtyzgV9g',
      'https://www.instagram.com/dol.vn_ieltsdinhluc/'
    ],
    name: 'DOL English',
    alternateName: 'DOL IELTS Đình Lực',
    url: 'https://www.dolenglish.vn/',
    image: {
      '@type': 'ImageObject',
      url: 'https://asset.dolenglish.vn/sFYcPsHEQbKIZMTiAGtL',
      width: '180',
      height: '69'
    }
  },
  copyrightHolder: { '@id': 'https://www.dolenglish.vn/#organization' },
  author: { '@id': 'https://www.dolenglish.vn/#organization' },
  publisher: { '@id': 'https://www.dolenglish.vn/#organization' },
  potentialAction: {
    '@type': 'InteractAction',
    agent: {
      '@type': 'Organization',
      '@id': 'https://www.dolenglish.vn/#organization',
      name: 'DOL IELTS Đình Lực',
      url: 'https://www.dolenglish.vn/'
    },
    participant: {
      '@type': 'Person',
      name: 'Student'
    }
  }
};

export const localBusiness = {
  '@context': 'http://schema.org',
  '@type': 'ProfessionalService',
  '@id': 'kg:/g/11hyxt33rj',
  name: 'Trung tâm luyện thi IELTS TOP 1 TP HCM - DOL English',
  slogan: 'Trung tâm luyện thi IELTS TOP 1 TP HCM - DOL English',
  alternateName: [
    'dolenglish.vn',
    'dolenglish',
    'Luyện thi IELTS DOL English',
    'Trung tâm luyện thi IELTS TOP 1 TP HCM - DOL English'
  ],
  description:
    'DOL English là Trung tâm luyện thi IELTS theo phương pháp tiếng anh tư duy đầu tiên tại Việt Nam, giáo viên chuyên môn cao & nền tảng tự học IELTS Online chất lượng',
  disambiguatingdescription:
    'DOL English là Trung tâm luyện thi IELTS theo phương pháp tiếng anh tư duy đầu tiên tại Việt Nam',
  image: ['https://asset.dolenglish.vn/Z45rorFoRlWpC1MChX7c'],
  url: 'https://www.dolenglish.vn/',
  mainEntityofPage: 'https://www.dolenglish.vn/',
  telephone: '1800 96 96 39',
  email: 'info@dolenglish.vn',
  priceRange: '1.000.000 vnđ - 100.000.000 vnđ',
  openingHoursSpecification: [
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      opens: '09:00',
      closes: '21:00'
    }
  ],
  contactPoint: {
    '@type': 'Contactpoint',
    url: 'tel:1800 96 96 39',
    telephone: '1800 96 96 39',
    contactType: 'customer service'
  },
  sameAs: [
    'https://www.facebook.com/dolenglish.ieltsdinhluc/',
    'https://www.youtube.com/channel/UCEhPLLt928jxgOGDtyzgV9g',
    'https://www.instagram.com/dol.vn_ieltsdinhluc/'
  ],
  currenciesAccepted: ['VND'],
  paymentAccepted: ['Cash', 'Credit Card', 'Visa'],
  award: ' Luyện thi IELTS DOL English',
  founder: {
    '@type': 'Person',
    '@id': 'https://www.dolenglish.vn/author/dolieltsdinhluc#person'
  },
  legalName: 'CÔNG TY TNHH DOL ENGLISH',
  foundingDate: '2018-07-19T00:00:00.000',
  numberOfEmployees: {
    '@type': 'QuantitativeValue',
    minvalue: '20',
    maxvalue: '100',
    value: '100'
  },
  foundingLocation: {
    '@type': 'place',
    name: 'Việt Nam',
    url: 'https://vi.wikipedia.org/wiki/Vi%E1%BB%87t_Nam',
    address:
      '458/14 Đường 3 Tháng 2, Phường 12, Quận 10, Thành phố Hồ Chí Minh, Việt Nam'
  },
  logo: 'https://asset.dolenglish.vn/Z45rorFoRlWpC1MChX7c',
  hasMap: [
    'https://www.google.com/maps?cid=11365211748785900386',
    'https://search.google.com/local/writereview?placeid=ChIJuaH4yKAvdTERYst6u95XuZ0',
    'https://www.google.com/maps/place/DOL+IELTS+%C4%90%C3%ACnh+L%E1%BB%B1c+-+H%E1%BB%8Dc+Vi%E1%BB%87n+Ti%E1%BA%BFng+Anh+T%C6%B0+Duy+%C4%91%E1%BA%A7u+ti%C3%AAn+t%E1%BA%A1i+Vi%E1%BB%87t+Nam/@10.7690646,106.6678783,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x9db957debb7acb62!8m2!3d10.7690646!4d106.6678783'
  ],
  hasOfferCatalog: [
    {
      '@type': 'OfferCatalog',
      name: 'Khóa học IELTS', //get h1 của URL
      url: 'https://www.dolenglish.vn/khoa-hoc-ielts', //url danh mục trong category
      '@id': 'https://www.dolenglish.vn/khoa-hoc-ielts#category', //url danh mục trong category
      numberOfItems: '4', //đếm số lượng danh mục trong category
      itemListElement: [
        {
          '@type': 'OfferCatalog',
          name: 'IELTS cấp tốc',
          image: 'https://asset.dolenglish.vn/RWSvaIZT5x9SgNAaCtwM', //get ảnh đại diện category
          description:
            'Khóa học luyện thi ielts cấp tốc uy tín, khóa học ielts cơ bản cho người mới bắt đầu, khóa nâng cao cho các band điểm 6.0+ và các khóa luyện thi online',
          url: 'https://www.dolenglish.vn/ielts-cap-toc',
          '@id': 'https://www.dolenglish.vn/ielts-cap-toc#category',
          itemListElement: [
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS Super Intensive',
                url: 'https://www.dolenglish.vn/ielts/ielts-super-intensive',
                image: 'https://asset.dolenglish.vn/Z8sTNsbTTm1oRTfNf513', //get ảnh đại diện category
                '@id':
                  'https://asset.dolenglish.vn/Z8sTNsbTTm1oRTfNf513#category',
                description:
                  'Khóa học IELTS Super Intensive giúp học viên cấp tốc tăng 2 band sau 2 tháng với lộ trình chuẩn Tư duy, Hình thành tư duy học tiếng Anh đúng...'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS Intensive 0',
                url: 'https://www.dolenglish.vn/ielts/ielts-intensive-0',
                image: 'https://asset.dolenglish.vn/fEMeKeSqTPa1prsxWIOX', //get ảnh đại diện category
                '@id':
                  'https://asset.dolenglish.vn/fEMeKeSqTPa1prsxWIOX#category',
                description:
                  'Khóa học IELTS Intensive 0 - Cấp tốc lấy lại căn bản - Đạt IELTS 5.0 sau 8 tuần, Xây nền tảng từ vựng, ngữ pháp căn bản, Nói và viết 1 đoạn văn thành thạo.'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS Intensive 1',
                url: 'https://www.dolenglish.vn/ielts/ielts-intensive-1',
                image: 'https://asset.dolenglish.vn/ZCN5Sq9mRF6XoqpPiSap', //get ảnh đại diện category
                '@id':
                  'https://www.dolenglish.vn/ielts/ielts-intensive-1#category',
                description:
                  'Khóa IELTS Intensive 1✓ Học Tư Duy Tiếng Anh✓ Chiến thuật hợp lý✓ 8 tuần cấp tốc đạt 6.5 IELTS như ý✓ Đảm bảo đầu ra IELTS 6.5+ sau 8 tuần.'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS Intensive 2',
                url: 'https://www.dolenglish.vn/ielts/ielts-intensive-2',
                image: 'https://asset.dolenglish.vn/dTwyl5GbQFC3zdI7eW8Y', //get ảnh đại diện category
                '@id':
                  'https://www.dolenglish.vn/ielts/ielts-intensive-2#category',
                description:
                  'Khóa học IELTS Intensive 2✓ Học Tư Duy✓ Rõ tiêu chí✓ 8 tuần cấp tốc lấy IELTS 7.0 như ý✓ Có kiến thức cơ bản về cấu trúc và cách tiếp cận bài thi IELTS...'
              }
            }
          ]
        },
        {
          '@type': 'OfferCatalog',
          name: 'IELTS cơ bản',
          image: 'https://asset.dolenglish.vn/tSOQyz9SQ7q287EbpJy2', //get ảnh đại diện category
          description:
            'Lộ trình tự học IELTS cơ bản cho người mới bắt đầu từ 0 lên 7.0+, luyện thi IELTS toàn diện 4 kỹ năng cho người mất gốc, tài liệu IELTS cơ bản đến nâng cao.',
          url: 'https://www.dolenglish.vn/ielts-co-ban',
          '@id': 'https://www.dolenglish.vn/ielts-co-ban#category',
          itemListElement: [
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'PRE IELTS',
                url: 'https://www.dolenglish.vn/ielts/ielts-pre',
                image: 'https://asset.dolenglish.vn/ci5dmZGjTCHKyPFRs4id', //get ảnh đại diện category
                '@id': 'https://www.dolenglish.vn/ielts/ielts-pre#category',
                description:
                  'Khóa học PRE IELTS giúp học viên định hướng lại cách học tiếng Anh đúng, hiểu được bản chất của tiếng Anh và sự khác biệt giữa tiếng Anh và tiếng Việt...'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS 4.0',
                url: 'https://www.dolenglish.vn/ielts/ielts-4',
                image: 'https://asset.dolenglish.vn/kiYSkKmVQe2aClQVOxm2', //get ảnh đại diện category
                '@id': 'https://www.dolenglish.vn/ielts/ielts-4#category',
                description:
                  'Khóa học IELTS 4.0 hiệu quả dành cho các học viên mất gốc và yếu kỹ năng, cam kết đầu ra 4.0+ giúp học viên cải thiện tư duy học Tiếng Anh trong vòng 9 tuần'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS 5.0',
                url: 'https://www.dolenglish.vn/ielts/ielts-5',
                image: 'https://asset.dolenglish.vn/0RVH9aBqRyaJimEmHkIg', //get ảnh đại diện category
                '@id': 'https://www.dolenglish.vn/ielts/ielts-5#category',
                description:
                  'Khóa học IELTS 5.0 chi tiết cho người mới bắt đầu giúp củng cố kiến thức cơ bản về cấu trúc và các dạng bài thi IELTS, mở vốn từ vựng & ngữ pháp ...'
              }
            }
          ]
        },
        {
          '@type': 'OfferCatalog',
          name: 'IELTS nâng cao',
          image: 'https://asset.dolenglish.vn/yInUqz8eSFSyrn7buz0u', //get ảnh đại diện category
          description:
            'Khóa học IELTS nâng cao tại DOL English ứng dụng Linearthinking giúp học viên nâng cao ngữ pháp, từ vựng và khả năng dùng tiếng Anh giúp tăng band điểm hiệu quả',
          url: 'https://www.dolenglish.vn/ielts-nang-cao',
          '@id': 'https://www.dolenglish.vn/ielts-nang-cao#category',
          itemListElement: [
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS 6.0',
                url: 'https://www.dolenglish.vn/ielts/ielts-6',
                image: 'https://asset.dolenglish.vn/ecF1m1yKR0iidc0lb5T9', //get ảnh đại diện category
                '@id': 'https://www.dolenglish.vn/ielts/ielts-6#category',
                description:
                  'Khóa học IELTS 6.0 mất bao lâu? Khóa học IELTS 6.0 tại DOL English trong 9 tuần giúp học viên nắm chắc các dạng đề và kiến thức mà bài thi IELTS yêu cầu'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS 6.5',
                url: 'https://www.dolenglish.vn/ielts/ielts-65',
                image: 'https://asset.dolenglish.vn/T23IlQpwSh6Z7fyfsfRI', //get ảnh đại diện category
                '@id': 'https://www.dolenglish.vn/ielts/ielts-65#category',
                description:
                  'Khóa học IELTS 6.5 cấp tốc trong 8 tuần rèn luyện kỹ năng nói và viết một đoạn/ một bài nhanh, lưu loát, tự nhiên và liên kết, kể cả khi gặp chủ đề lạ.'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS 7.0',
                url: 'https://www.dolenglish.vn/ielts/ielts-7',
                image: 'https://asset.dolenglish.vn/CvGV4hgMRaS41j8iPFVF', //get ảnh đại diện category
                '@id': 'https://www.dolenglish.vn/ielts/ielts-7#category',
                description:
                  'Khóa học IELTS 7.0 chi tiết giúp học viên có kiến thức về cấu trúc đề thi và cách tiếp cận các dạng bài thi IELTS, đảm bảo đầu ra tối thiểu 7.0+ cho học viên'
              }
            },
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'Khóa Writing Speaking Aim 8.0',
                url: 'https://www.dolenglish.vn/ielts/writing-speaking-8',
                image: 'https://asset.dolenglish.vn/5fbFghPtRf6our4fQa10', //get ảnh đại diện category
                '@id':
                  'https://www.dolenglish.vn/ielts/writing-speaking-8#category',
                description:
                  'Khóa Học IELTS Writing + Speaking tại DOL giúp học viên hiểu rõ cấu trúc bài thi IELTS Writing & Speaking cũng như cách tiếp cận bài thi IELTS hiệu quả.'
              }
            }
          ]
        },
        {
          '@type': 'OfferCatalog',
          name: 'IELTS Online',
          image: 'https://asset.dolenglish.vn/ZSkB3XTHQOSExx4YeHn3', //get ảnh đại diện category
          description:
            'Học IELTS Online trải nghiêm như offline với học phí chỉ 2/3 cùng phương pháp Linear Thinking tại Dol English...Đăng ký ngay!',
          url: 'https://www.dolenglish.vn/hoc-ielts-online',
          '@id': 'https://www.dolenglish.vn/hoc-ielts-online#category',
          itemListElement: [
            {
              '@type': 'Offer',
              itemOffered: {
                '@type': 'MenuItem',
                name: 'IELTS Online',
                url: 'https://www.dolenglish.vn/hoc-ielts-online',
                image: 'https://asset.dolenglish.vn/ZSkB3XTHQOSExx4YeHn3', //get ảnh đại diện category
                '@id': 'https://www.dolenglish.vn/hoc-ielts-online#category',
                description:
                  'Học IELTS Online trải nghiêm như offline với học phí chỉ 2/3 cùng phương pháp Linear Thinking tại Dol English...Đăng ký ngay!'
              }
            }
          ]
        }
      ]
    },
    {
      '@type': 'OfferCatalog',
      name: 'Khóa học khác',
      url: 'https://www.dolenglish.vn/khoa-hoc-khac', //url danh mục trong category
      '@id': 'https://www.dolenglish.vn/khoa-hoc-khac#category', //url danh mục trong category
      numberOfItems: '3', //số lượng danh mục trong category
      itemListElement: [
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'MenuItem',
            name: 'TOEIC',
            url: 'https://www.dolenglish.vn/toeic',
            image: 'https://asset.dolenglish.vn/BCNq60sQFevzs2mBkuzi', //get ảnh đại diện category
            '@id': 'https://www.dolenglish.vn/toeic#category',
            description:
              'Khóa học TOEIC tại DOL tập trung vào xây dựng kiến thức nền tảng, cho học viên làm quen với Linearthinking làm nền tảng tiếp thu những kiến thức chuyên sâu.'
          }
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'MenuItem',
            name: 'SAT',
            url: 'https://www.dolenglish.vn/sat',
            image: 'https://asset.dolenglish.vn/zbELDlK3QGL4KBJzdRrQ', //get ảnh đại diện category
            '@id': 'https://www.dolenglish.vn/sat#category',
            description:
              'DOL English là trung tâm luyện thi SAT ở TpHCM uy tín và tốt nhất, áp dụng Linearthinking giúp cấp tốc đạt mục tiêu SAT từ 1300 - 1500+ trong vòng 9 tuần'
          }
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'MenuItem',
            name: 'Tiếng Anh giao tiếp',
            url: 'https://www.dolenglish.vn/tieng-anh-giao-tiep',
            image: 'https://asset.dolenglish.vn/1OJijgZvTxGXcbGHiNla', //get ảnh đại diện category
            '@id': 'https://www.dolenglish.vn/tieng-anh-giao-tiep#category',
            description:
              'Khóa học tiếng Anh giao tiếp dành cho ngừời đi làm, khóa học tiếng Anh giao tiếp online uy tín, hiệu quả, chuyên nghiệp nhất tại Tp. HCM'
          }
        }
      ]
    }
  ],
  hasMerchantReturnPolicy: 'https://www.dolenglish.vn/dieu-khoan-su-dung',
  address: {
    '@type': 'PostalAddress',
    name: 'Head office',
    streetAddress:
      '458/14 Đường 3 Tháng 2, Phường 12, Quận 10, Thành phố Hồ Chí Minh, Việt Nam',
    addressLocality: 'Quận 10',
    addressRegion: 'Thành phố Hồ Chí Minh',
    addressCountry: 'Việt Nam',
    postalCode: '72500'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '10.7690646',
    longitude: '106.6678783'
  },
  areaServed: [
    {
      '@type': 'Geoshape',
      address: {
        postalCode: '72500',
        addressCountry: 'Việt Nam',
        streetAddress:
          '458/14 Đường 3 Tháng 2, Phường 12, Quận 10, Thành phố Hồ Chí Minh, Việt Nam'
      }
    }
  ]
};

export const contactPage = {
  '@context': 'https://schema.org',
  '@type': 'ContactPage',
  inLanguage: 'vi-VN',
  url: 'https://www.dolenglish.vn/lien-he',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.dolenglish.vn/lien-he#ContactPage'
  },
  name: 'Liên hệ Dol English',
  headline: 'Liên hệ Online và Offline với Dol English',
  alternativeheadline: 'Địa chỉ liên lạc Dol English',
  description:
    'Xem thông tin liên hệ, đăng ký luyện thi IELTS bằng phương pháp tư duy tuyến tính độc quyền ở Việt Nam tại Trung tâm IELTS Đình Lực - DOL English',
  primaryImageOfPage: 'https://asset.dolenglish.vn/EFl5JYYTEGl5yJMgW7ww',
  publisher: {
    '@type': 'Organization',
    '@id': 'https://www.dolenglish.vn/#organization'
  },
  about: [
    {
      '@type': 'ContactPoint',
      hoursAvailable: {
        '@type': 'OpeningHoursSpecification',
        name: 'Giờ hoạt động của DOL English',
        opens: '09:00:00',
        closes: '21:00:00',
        dayOfWeek: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ],
        '@id': 'https://schema.org/OpeningHoursSpecification'
      },
      availableLanguage: ['Vienamese'],
      description: 'Liên hệ DOL English nếu bạn cần trợ giúp',
      telephone: '1800 96 96 39',
      contactType: 'Customer Service',
      email: 'info@dolenglish.vn',
      name: 'Nhân viên chăm sóc khách hàng',
      url: 'https://www.dolenglish.vn/lien-he#customerservice',
      areaServed: {
        '@type': 'GeoCircle',
        geoMidpoint: {
          '@type': 'GeoCoordinates',
          latitude: 10.7690646,
          longitude: 106.6678783
        },
        geoRadius: 1000
      },
      contactOption: [
        {
          '@type': 'ContactPointOption',
          name: 'Cơ sở Quận 10',
          description: 'Liên hệ Cơ sở Quận 10'
        }
      ]
    },
    {
      '@type': 'ContactPoint',
      hoursAvailable: {
        '@type': 'OpeningHoursSpecification',
        name: 'Giờ hoạt động của DOL English',
        opens: '09:00:00',
        closes: '21:00:00',
        dayOfWeek: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ],
        '@id': 'https://schema.org/OpeningHoursSpecification'
      },
      availableLanguage: ['Vietnamese'],
      description: 'Liên hệ DOL English nếu bạn cần trợ giúp',
      telephone: '1800 96 96 39',
      contactType: 'Customer service',
      email: 'info@dolenglish.vn',
      name: 'Nhân viên tư vấn bán hàng',
      url: 'https://www.dolenglish.vn/lien-he#customerservice',
      areaServed: {
        '@type': 'GeoCircle',
        geoMidpoint: {
          '@type': 'GeoCoordinates',
          latitude: 10.7690646,
          longitude: 106.6678783
        },
        geoRadius: 1000
      },
      contactOption: [
        {
          '@type': 'ContactPointOption',
          name: 'Cơ sở Tân Bình',
          description: 'Liên hệ Cơ sở Tân Bình'
        }
      ]
    }
  ],
  '@id': 'https://www.dolenglish.vn/lien-he'
};

export const aboutPage = {
  '@context': 'https://schema.org',
  '@type': 'AboutPage',
  '@id': 'https://www.dolenglish.vn/about-us#AboutPage',
  url: 'https://www.dolenglish.vn/about-us',
  mainEntityOfPage: {
    '@type': 'WebPage',
    url: 'https://www.dolenglish.vn/about-us'
  },
  inLanguage: 'vi-VN',
  headline: 'Trung Tâm Anh ngữ hàng đầu HCM - IELTS Đình Lực | DOL English',
  name: 'Giới thiệu Trung Tâm Anh ngữ hàng đầu HCM - IELTS Đình Lực | DOL English',
  description:
    'DOL English - trung tâm anh ngữ hàng đầu tại Tp HCM với phương pháp giảng dạy tiếng Anh độc quyền, hiệu quả vượt trội được công nhận bởi hàng ngàn học viên',
  primaryImageOfPage: 'https://asset.dolenglish.vn/CLiCmLeRiaUEeTN9YLpJ',
  thumbnailUrl: 'https://asset.dolenglish.vn/CLiCmLeRiaUEeTN9YLpJ',
  image: ['https://asset.dolenglish.vn/CLiCmLeRiaUEeTN9YLpJ'],
  license: 'https://www.dolenglish.vn/chinh-sach-bao-mat',
  alternativeheadline:
    'Trung Tâm Anh ngữ hàng đầu HCM - IELTS Đình Lực | DOL English',
  relatedLink: [
    'https://www.dolenglish.vn/lien-he',
    'https://www.dolenglish.vn/chinh-sach-bao-mat',
    'https://www.dolenglish.vn/dieu-khoan-su-dung',
    'https://www.dolenglish.vn/author/dolieltsdinhluc'
  ],
  publisher: {
    '@type': 'Organization',
    '@id': 'https://www.dolenglish.vn/#organization'
  }
};

export const educationalOrganization = {
  '@context': 'https://schema.org',
  '@type': 'EducationalOrganization',
  image: 'https://media.dolenglish.vn/PUBLIC/MEDIA/Logo_0efdf497dd.png',
  founder: {
    '@type': 'Person',
    name: 'Lê Đình Lực',
    URL: '',
    Address:
      '458/14 Hẻm 458 Ba Tháng Hai, Phường 12, Quận 10, Thành phố Hồ Chí Minh'
  },
  additionalType: [
    'https://www.facebook.com/dolenglish.ieltsdinhluc/',
    'https://twitter.com/english_dol',
    'https://www.instagram.com/dol_english_vn/',
    'https://dolenglish.tumblr.com/',
    'https://vn.linkedin.com/in/dol-english-57a826175',
    'https://about.me/dol_english',
    'https://iheart.com/podcast/106434637/',
    'https://open.spotify.com/show/0O16qZXtG1ygoJFYU82UBZ',
    'https://www.youtube.com/channel/UCEhPLLt928jxgOGDtyzgV9g'
  ],
  openingHoursSpecification: [
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      opens: '9:00:00',
      closes: '21:00:00'
    }
  ],
  sameAs: [
    'https://tuoitre.vn/tung-ngan-tieng-anh-thanh-cong-ngay-khi-khoi-nghiep-voi-phuong-phap-hoc-van-nguoi-me-20220309112543695.htm',
    'https://vnexpress.net/hoc-tieng-anh-dua-tren-toan-tu-duy-va-tri-tue-nhan-tao-3967889.html',
    'https://masothue.com/0315174306-cong-ty-tnhh-dol-english',
    'https://baodautu.vn/le-dinh-luc-nha-sang-lap-dolvn-hoc-tieng-anh-de-dang-hon-voi-cong-nghe-phuong-phap-tu-duy-d161029.html'
  ],
  url: 'https://www.dolenglish.vn/',
  '@id': 'kg:/g/11hyxt33rj ',
  potentialAction: {
    result: {
      '@type': 'Reservation',
      name: 'Liên hệ'
    },
    '@type': 'ReserveAction',
    target: {
      '@type': 'EntryPoint',
      inLanguage: 'vn',
      actionPlatform: [
        ' http://schema.org/DesktopWebPlatform',
        'http://schema.org/IOSPlatform',
        'http://schema.org/AndroidPlatform'
      ],
      urlTemplate: 'https://www.dolenglish.vn/lien-he'
    }
  },
  name: 'Trung tâm luyện thi IELTS DOL Đình Lực',
  alternatename: 'Trung tâm luyện thi IELTS TOP 1 TP HCM - DOL English',
  description:
    'DOL IELTS Đình Lực là Trung tâm luyện thi IELTS Tư duy đầu tiên tại Việt Nam, giáo viên chuyên môn cao nền tảng tự học IELTS Online chất lượng',
  disambiguatingdescription:
    'Hơn 9000 thành viên hoạt động sôi nổi cùng học hỏi kinh nghiệm luyện thi PTE, mẹo và chiến thuật làm bài hiệu quả từ hàng nghìn học viên đã đạt điểm cao và thành công với PTE HELPER.',
  logo: {
    type: 'ImageObject',
    '@id': 'https://media.dolenglish.vn/PUBLIC/MEDIA/Logo_0efdf497dd.png',
    url: 'https://media.dolenglish.vn/PUBLIC/MEDIA/Logo_0efdf497dd.png'
  },
  hasMap: [
    'https://goo.gl/maps/5U466qvDNzaZNkcj7',
    'https://goo.gl/maps/5EuA1xsj9sikURqC8',
    'https://goo.gl/maps/9eCUMMVXQyFzYsTq5',
    'https://goo.gl/maps/d86upZYUNESHRPRS9',
    'https://goo.gl/maps/Uc64CmnA13kC3RJg6',
    'https://goo.gl/maps/qfhyhGsdqNZR6wWV9',
    'https://goo.gl/maps/BcPwUpfy86FfuTaz5'
  ],
  mainEntityOfPage: 'https://www.dolenglish.vn/about-us',
  email: ['info@dolenglish.vn'],
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: ['1800 96 96 39'],
    contactType: 'Customer support',
    email: 'info@dolenglish.vn'
  },
  telephone: '1800 96 96 39',
  address: [
    {
      '@type': 'PostalAddress',
      addressCountry: 'Việt Nam',
      addressLocality: 'Quận 10',
      addressRegion: 'Hồ Chí Minh',
      streetAddress: '458/14 Hẻm 458 Ba Tháng Hai, Phường 12',
      postalCode: '700000'
    },
    {
      '@type': 'PostalAddress',
      addressCountry: 'Việt Nam',
      addressLocality: 'Quận 4',
      addressRegion: 'Hồ Chí Minh',
      streetAddress: '219 Khánh Hội, Phường 5, Quận 4',
      postalCode: '700000'
    },
    {
      '@type': 'PostalAddress',
      addressCountry: 'Việt Nam',
      addressLocality: 'Quận 6',
      addressRegion: 'Hồ Chí Minh',
      streetAddress: '16 Đường số 3, Phường 11, Quận 6',
      postalCode: '700000'
    },
    {
      '@type': 'PostalAddress',
      addressCountry: 'Việt Nam',
      addressLocality: 'Quận 9',
      addressRegion: 'Hồ Chí Minh',
      streetAddress: '25b Đường Lê Văn Việt, Hiệp Phú, Quận 9',
      postalCode: '700000'
    },
    {
      '@type': 'PostalAddress',
      addressCountry: 'Việt Nam',
      addressLocality: 'Quận Bình Thạnh',
      addressRegion: 'Hồ Chí Minh',
      streetAddress: '302 Nguyễn Văn Đậu, Bình Thạnh',
      postalCode: '700000'
    },
    {
      '@type': 'PostalAddress',
      addressCountry: 'Việt Nam',
      addressLocality: 'Quận Gò Vấp',
      addressRegion: 'Hồ Chí Minh',
      streetAddress: '22 Đường số 3, Cityland Park Hills',
      postalCode: '700000'
    },
    {
      '@type': 'PostalAddress',
      addressCountry: 'Việt Nam',
      addressLocality: 'Quận Tân Bình',
      addressRegion: 'Hồ Chí Minh',
      streetAddress: '18H Cộng Hòa, Phường 4',
      postalCode: '700000'
    }
  ],
  foundingLocation: {
    '@type': 'Place',
    name: 'Hồ Chí Minh',
    url: ' https://en.wikipedia.org/wiki/Ho_Chi_Minh_City',
    address: 'Việt Nam'
  },
  geo: [
    {
      '@type': 'GeoCoordinates',
      latitude: '10.7690646',
      longitude: '106.6678783'
    },
    {
      '@type': 'GeoCoordinates',
      latitude: '10.7582976',
      longitude: '106.6991462'
    },
    {
      '@type': 'GeoCoordinates',
      latitude: '10.745801',
      longitude: '106.63401'
    },
    {
      '@type': 'GeoCoordinates',
      latitude: '10.8452894',
      longitude: '106.7793322'
    },
    {
      '@type': 'GeoCoordinates',
      latitude: '10.8147676',
      longitude: '106.6944158'
    },
    {
      '@type': 'GeoCoordinates',
      latitude: '10.8343478',
      longitude: '106.6705856'
    },
    {
      '@type': 'GeoCoordinates',
      latitude: '10.8013588',
      longitude: '106.6552402'
    }
  ],
  legalName: 'Trung tâm luyện thi IELTS DOL Đình Lực',
  foundingDate: '2018',
  numberOfEmployees: '150',
  hasOfferCatalog: {
    '@type': 'OfferCatalog',
    itemListElement: [
      {
        '@type': 'Offer',
        name: 'Khóa học IELTS',
        itemOffered: 'Service',
        url: 'https://www.dolenglish.vn/khoa-hoc-ielts'
      },
      {
        '@type': 'Offer',
        name: 'IELTS cấp tốc',
        itemOffered: 'Service',
        url: 'https://www.dolenglish.vn/ielts-cap-toc'
      },
      {
        '@type': 'Offer',
        name: 'IELTS cơ bản',
        itemOffered: 'Service',
        url: 'https://www.dolenglish.vn/ielts-co-ban'
      },
      {
        '@type': 'Offer',
        name: 'IELTS nâng cao',
        itemOffered: 'Service',
        url: 'https://www.dolenglish.vn/ielts-nang-cao'
      },
      {
        '@type': 'Offer',
        name: 'IELTS ONLINE & 1-1',
        itemOffered: 'Service',
        url: 'https://www.dolenglish.vn/hoc-ielts-online'
      }
    ]
  },
  areaServed: {
    '@type': 'Country',
    url: 'https://www.wikidata.org/wiki/Q881',
    name: 'Việt Nam'
  }
};
