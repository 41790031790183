import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import getAssetLink from '../../utils/getAssetLink';
import { organization, webSite, educationalOrganization } from './constant';
import { useLoadThanhSeoScript } from '../../utils/hooks/useLoadThanhSeoScript';

const defaultFeatureImg = {
  handle: 'H8oRpqObSTZoHXWXObNs'
};
const defaultTitle =
  'Khóa học IELTS Writing và Speaking chuyên sâu tại DOL ENGLISH';
const defaultDesc =
  'Khóa học IELTS Writing và Speaking giúp bạn hiểu rõ cấu trúc và cách tiếp cận bài thi IELTS Writing và Speaking đồng thời giúp bạn xây dựng tư duy đúng về việc học tiếng anh.';

const postFix = 'DOL English';

const MySEO = ({
  title = defaultTitle,
  description = defaultDesc,
  type = 'website',
  featureImg = null,
  noSEO,
  children,
  hidePostfix = true,
  h1,
  slug,
  homePage,
  breadCrumbSchema
}) => {
  useLoadThanhSeoScript();

  const featureImgLink = featureImg
    ? getAssetLink({ item: { handle: featureImg } })
    : getAssetLink({ item: defaultFeatureImg, width: 500 });
  const titleSEO = hidePostfix ? `${title}` : `${title} | ${postFix}`;
  const pageUrl = process.env.SITE_URL + slug;

  const wpHeader = {
    '@context': 'http://schema.org/',
    '@type': 'WPHeader',
    '@id': `${pageUrl}/header`
  };

  const siteNavigation = {
    '@context': 'http://schema.org/',
    '@type': 'SiteNavigationElement',
    '@id': `${pageUrl}/navigation`
  };

  const wpFooter = {
    '@context': 'http://schema.org/',
    '@type': 'WPFooter',
    '@id': `${pageUrl}/footer`
  };

  const webPage = {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    '@id': `${pageUrl}#webpage`,
    url: pageUrl,
    inLanguage: 'vi-VN',
    name: h1,
    isPartOf: {
      '@type': 'WebSite',
      '@id': 'https://www.dolenglish.vn/#website'
    },
    image: {
      '@type': 'ImageObject',
      '@id': `${pageUrl}#primaryimage`,
      url: featureImgLink,
      caption: h1
    },
    primaryImageOfPage: {
      '@id': `${pageUrl}#primaryimage`
    },
    datePublished: '2022-03-28T14:05:01+00:00',
    dateModified: '2022-04-11T12:24:14+00:00',
    description: description
  };

  const createtiveWorkSeries = {
    '@context': 'https://schema.org',
    '@type': 'CreativeWorkSeries',
    url: pageUrl,
    '@id': `${pageUrl}#CreativeWorkSeries`,
    name: h1,
    headline: title,
    description: description,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: (Math.random() * 0.5 + 4.5).toFixed(1),
      bestRating: 5,
      ratingCount: Math.floor(Math.random() * 201) + 50
    }
  };

  const breadcrumbListDefault = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    '@id': `${pageUrl}#breadcrumb`,
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@type': 'WebPage',
          '@id': 'https://www.dolenglish.vn',
          url: 'https://www.dolenglish.vn',
          name: 'Trang chủ'
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': pageUrl,
          url: pageUrl,
          name: h1
        }
      }
    ]
  };
  const renderSchema = React.useCallback(() => {
    const schemaList = homePage
      ? [webSite]
      : [
          webPage,
          createtiveWorkSeries,
          breadCrumbSchema || breadcrumbListDefault
        ];
    return schemaList.map(elm => (
      <script type="application/ld+json" key={elm}>
        {JSON.stringify(elm)}
      </script>
    ));
  }, [
    webPage,
    createtiveWorkSeries,
    homePage,
    breadCrumbSchema,
    breadcrumbListDefault
  ]);

  const isDevEnv = !process.env.IS_PROD;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'vi'
      }}
    >
      <meta charSet="utf-8" />
      <title>{titleSEO}</title>
      <meta name="title" content={titleSEO} />
      <meta name="description" content={description} />

      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="DOL English" />
      <meta property="og:image" content={featureImgLink} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:locale" content="vi_VN" />
      <meta property="og:image:type" content="image/jpeg" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@EnglishDol" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={featureImgLink} />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      {homePage && <link rel="canonical" href={process.env.SITE_URL}></link>}
      <script type="application/ld+json">{JSON.stringify(organization)}</script>
      <script type="application/ld+json">
        {JSON.stringify(educationalOrganization)}
      </script>
      <script type="application/ld+json">{JSON.stringify(wpHeader)}</script>
      <script type="application/ld+json">{JSON.stringify(wpFooter)}</script>
      <script type="application/ld+json">
        {JSON.stringify(siteNavigation)}
      </script>
      <script src="https://sp.zalo.me/plugins/sdk.js"></script>

      {renderSchema()}

      {(isDevEnv || noSEO) && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {children}
    </Helmet>
  );
};

MySEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  featureImg: PropTypes.shape({}),
  noSEO: PropTypes.bool,
  children: PropTypes.node
};

export default MySEO;
